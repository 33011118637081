import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createBlock(_component_el_backtop, _mergeProps({
    class: "z-backtop",
    bottom: _ctx.bottom
  }, _toHandlers(_ctx.$attrs)), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["bottom"]))
}