import {
	createRouter,
	createWebHistory,
	RouteRecordRaw,
	RouteLocationNormalized,
} from 'vue-router'
import Layout from '@/layout/index.vue'
import { getToken } from '@/utils/auth'

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '/',
				name: 'home',
				meta: { keepAlive: true, auth: true },
				component: () => import('@/views/home.vue'),
			},
			{
				path: '/wechat',
				name: 'wechat-home',
				meta: { keepAlive: true, auth: true },
				component: () => import('@/views/home.vue'),
			},
			{
				path: '/order',
				name: 'order',
				meta: { keepAlive: true, auth: true },
				component: () => import('@/views/order/index.vue'),
			},
			{
				path: '/order/packages',
				name: 'packages',
				meta: { keepAlive: true, auth: true },
				component: () => import('@/views/order/packages.vue'),
			},
			{
				path: '/order/unpaid-packages',
				name: 'unpaidPackages',
				meta: { keepAlive: true, auth: true },
				component: () => import('@/views/order/unpaid.vue'),
			},
			{
				path: '/user/login',
				name: 'user-login',
				component: () => import('@/views/user/login.vue'),
				meta: { auth: false },
			},
			{
				path: '/user/warehouse',
				name: 'user-warehouse',
				component: () => import('@/views/user/warehouse.vue'),
				meta: { auth: false },
			},
			{
				path: '/user/mine',
				name: 'user-mine',
				component: () => import('@/views/user/mine.vue'),
				meta: { auth: false },
			},
		],
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/error.vue'),
		meta: { hidden: true, auth: false },
		children: [],
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/404',
		component: () => import('@/views/error.vue'),
		meta: { hidden: true, auth: false },
		children: [],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})
router.beforeEach(
	async (
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: any
	) => {
		const { path, meta, name } = to
		const token = getToken()
		if ((token && meta.auth) || name == 'home') {
			return next()
		} else if (!token && path.indexOf('/user') === -1) {
			return next('/user/login')
		} else if (path.indexOf('404') !== -1) {
			return next('/404')
		}
		next()
	}
)

export default router
