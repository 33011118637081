
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ZBacktop',
	props: {
		bottom: {
			type: Number,
			default: 100,
		},
	},
})
