import { getToken, removeToken } from '@/utils/auth'
import axios, { Axios, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
const baseUrl = process.env.VUE_APP_API_BASE_URL
let unAuthorization = false

const service = axios.create({
	baseURL: baseUrl,
	timeout: 30000,
})
service.interceptors.request.use(
	(config: any) => {
		config.params = Object.assign(config.params || {}, {
			_: +new Date(), //disabled http cache
		})
		unAuthorization = false
		const bearerToken = getToken()
		if (bearerToken) {
			config.headers['Authorization'] = `Bearer ${bearerToken || ''}`
		}
		return config
	},
	(error: any) => {
		return Promise.reject(error)
	}
)
service.interceptors.response.use(
	(res: AxiosResponse) => {
		const status = res.status
		if (status >= 200 && status < 300) {
			return Promise.resolve(res)
		} else {
			return Promise.reject(res)
		}
	},
	(error: any) => {
		let message = error.message || '操作失败，请重试'
		const { status, data } = error.response || {}
		const noPermission = status === 401 || status === 403
		if (noPermission) {
			// 用户信息过期, 删除用户token
			removeToken()
			if (window.location.href !== `${window.location.origin}/user/login`) {
				router
					.push({
						path: '/user/login',
					})
					.catch((error: Error) => error)
			}
		}
		if (data && data.message) message = data.message
		if (Array.isArray(message)) message = message[0]
		message = message.replace('_', ' ')
		if (unAuthorization && noPermission) return
		ElMessage({
			offset: 64,
			message,
			showClose: true,
			type: 'error',
		})
		if (noPermission) unAuthorization = true
		// 接口错误message统一提示
		return Promise.reject(error)
	}
)

export default service
