import Storage from './storage'
import Cookies from 'js-cookie'

// User
const tokenKey = 'LOGIN_ACCESS_TOKEN'
export const getToken = () => Storage.get(tokenKey)
export const setToken = (token: string) => {
	Storage.set(tokenKey, token)
	const domain =
		location.hostname.indexOf('localhost') > -1
			? location.hostname
			: '.gblinker.com'
	Cookies.set(tokenKey, 'Bearer ' + token, {
		domain: domain,
	})
}
export const removeToken = () => {
	window.localStorage.removeItem(tokenKey)
	Cookies.remove(tokenKey)
}

const languageKey = 'language'
export const getLanguage = () => Storage.get(languageKey)
export const setLanguage = (language: string) =>
	Storage.set(languageKey, language)
