import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/styles/index.scss'
import { App } from 'vue'
import { zhCn } from 'element-plus/es/locales'

export default {
	install: (app: App) => {
		app.use(ElementPlus, {
			locale: zhCn,
		})
		const components = require.context('./', false, /\.vue$/)
		components.keys().map((filename) => {
			const name = filename.replace(/^\.\//, '').replace(/\.vue$/, '')
			const config = components(filename)
			app.component(name, config.default || config)
		})
	},
}
