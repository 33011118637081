export const echo = function (msg: any) {
	console.log(msg)
}

export const isWechat = () => {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	const ua = window.navigator.userAgent.toLowerCase()
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (/MicroMessenger/i.test(ua)) {
		return true
	} else {
		return false
	}
}
export const isMobile = (): boolean => {
	return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/.test(
		navigator.userAgent
	)
}

export const stringify = (params: any) => {
	return Object.keys(params)
		.map((key) => {
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')
}
