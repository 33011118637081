
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ZPagination',
	props: {
		layout: {
			type: String,
			default: 'sizes,prev, pager, next, jumper, ,total',
		},
		pageSizes: {
			type: Array,
			default: () => [20, 50, 100],
		},
	},
	created() {
		console.log(this.$props)
	},
})
