import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e64c493e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(({ Component, route }) => [
      _createElementVNode("section", _hoisted_1, [
        (_ctx.keepAliveCache)
          ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: route.fullPath
              }))
            ], 1024 /* DYNAMIC_SLOTS */))
          : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.fullPath
            }))
      ])
    ]),
    _: 1 /* STABLE */
  }))
}