
import { isNaN } from 'lodash'
import { defineComponent } from 'vue'
const defaultHeight = 900

export default defineComponent({
	name: 'ZPage',
	props: {
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		fixedFooter: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			mainHeight: defaultHeight,
		}
	},
	async mounted() {
		await this.$nextTick()
		this.getContentHeight()
	},
	methods: {
		getContentHeight: function () {
			const page = this.$refs.page as HTMLElement
			const parentNode =
				(page.parentNode as HTMLElement) ||
				document.getElementsByClassName('main-warp')
			const containerHeight = parentNode.clientHeight || 1300
			const headerHeight = this.$refs.header
				? (this.$refs.header as HTMLElement).clientHeight
				: 0
			const footerHeight = this.$refs.footer
				? (this.$refs.footer as HTMLElement).clientHeight
				: 0
			const paddingTop = this.getStyle(parentNode, 'padding-top') || 20
			const paddingBottom = this.getStyle(parentNode, 'padding-bottom') || 20
			const height =
				containerHeight -
				headerHeight -
				footerHeight -
				paddingTop -
				paddingBottom
			const tableHeight = this.$route.meta?.tableHeight
			const defaultTableHeight: any = tableHeight || defaultHeight
			if (!isNaN(height) && height >= defaultTableHeight) {
				this.mainHeight = height
			} else {
				this.mainHeight = defaultTableHeight
			}
		},
		getStyle: function (obj: any, attr: string) {
			// 兼容IE浏览器
			const result = obj.currentStyle
				? obj.currentStyle[attr].replace('px', '')
				: (window.getComputedStyle(obj, null) as any)[attr].replace('px', '')
			return Number(result)
		},
		styles: function () {
			if (this.$route.meta?.setMainHeight === false) return {}
			return { height: `${this.mainHeight}px` }
		},
	},
})
