
import { defineComponent, ref } from 'vue'
import AppMain from '@/layout/app-main.vue'
import { BHeader, BFooter } from '@/components/biz'
import { debounce } from 'lodash'
import { getTransitionName } from './transition'
import { ArrowUp } from '@element-plus/icons'

export default defineComponent({
	name: 'Layout',
	components: { AppMain, BHeader, BFooter, ArrowUp },
	setup() {
		let backtopPos = ref({
			right: 20,
			bottom: 80,
		})
		const debouncedResize = debounce(() => {
			const clientWidth = document.body.clientWidth
			if (clientWidth < 560) {
				backtopPos.value.right = 20
				backtopPos.value.bottom = 80
			} else {
				backtopPos.value.right = 35
				backtopPos.value.bottom = 100
			}
		}, 300)
		window.addEventListener('resize', debouncedResize)
		return {
			backtopPos,
		}
	},
})
