import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13877386"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "wrapper",
  class: "app-wrapper"
}
const _hoisted_2 = { class: "fixed-header" }
const _hoisted_3 = { class: "main-container" }
const _hoisted_4 = { class: "fixed-footer" }
const _hoisted_5 = { class: "backtop-up" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_header = _resolveComponent("b-header")!
  const _component_app_main = _resolveComponent("app-main")!
  const _component_b_footer = _resolveComponent("b-footer")!
  const _component_ArrowUp = _resolveComponent("ArrowUp")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_z_backtop = _resolveComponent("z-backtop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_main),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_b_footer)
      ]),
      _createVNode(_component_z_backtop, {
        starget: "#app",
        right: _ctx.backtopPos.right,
        bottom: _ctx.backtopPos.bottom
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_icon, { size: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowUp)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["right", "bottom"])
    ])
  ], 512 /* NEED_PATCH */))
}