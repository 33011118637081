
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'AppMain',
	computed: {
		key() {
			return this.$route.path
		},
		keepAliveCache() {
			return this.$route.meta?.keepAlive
		},
	},
})
