
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ZButton',
	props: {
		size: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'primary',
		},
	},
})
