import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'

import request from './utils/request'

import BaseComponent from './components/base'

async function bootstrap() {
	const app = createApp(App)
	app.use(store).use(router).use(BaseComponent)
	app.config.globalProperties.$axios = request
	app.provide('$wx', window.wx)
	app.mount('#app')
}
bootstrap()
