
import { defineComponent } from 'vue'
import { isWechat } from '@/utils/utils'
// import { ElMessage } from "element-plus";

export default defineComponent({
	name: 'BDialogLogin',
	props: {
		visible: Boolean,
	},
	data() {
		return {
			login: {
				mobile: '',
				sendcode: '',
				redirect: null,
			},
			disabled: false,
			showQrcode: false,
			dialogVisible: true,
			dialogWidth: '340px',
		}
	},
	computed: {
		user(): any {
			return this.$store.state.user
		},
	},
	watch: {
		visible: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.wechatLogin()
				this.dialogVisible = value
			},
		},
	},
	created() {
		this.initWechatQrcode()
		let clientWidth = document.body.clientWidth - 10
		this.dialogWidth = clientWidth < 340 ? clientWidth + 'px' : '340px'
	},
	methods: {
		async initWechatQrcode() {
			const { host, protocol } = location
			const { appid, scope } = await this.getInitInfo()
			// const redirectUrl = `${protocol}//${host}/?appid=${appid}`
			const redirectUrl = `https://merchants.gblinker.com/?appid=${appid}`
			new WxLogin({
				self_redirect: false,
				id: 'wechat-qrcode',
				appid,
				scope,
				redirect_uri: encodeURIComponent(redirectUrl),
				state: +new Date(),
				style: 'white',
				href: '//res-cn.public.gblinker.com/gblinker/merchants/styles/qrcode.css',
			})
		},
		async getInitInfo() {
			const { data } = await this.$axios.get('/v1/merchants/user/login/init')
			return data
		},
		dialogClose() {
			this.$emit('close')
			this.$emit('update:visible', false)
		},
		/** 显示网页微信客户端二维码 */
		showWechatQrcode() {
			this.initWechatQrcode()
			this.showQrcode = true
		},
		/** 微信登录 */
		async wechatLogin() {
			if (!isWechat()) return false
			if (this.user.logined) return false
			const { host, protocol } = location
			const redirectUrl = `${protocol}//${host}/wechat?appid=wx5ee8fe90b3847996`
			location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ee8fe90b3847996&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${+new Date()}#wechat_redirect`
		},
	},
})
