
import { defineComponent } from 'vue'
import { Document, Coffee, Money } from '@element-plus/icons'
import { getToken, removeToken } from '@/utils/auth'

export default defineComponent({
	name: 'BHeader',
	components: {
		Document,
		Coffee,
		Money,
	},
	computed: {
		activeName() {
			return this.$route.name
		},
		showLogin() {
			return !getToken()
		},
	},
	methods: {
		logout() {
			removeToken()
			location.href = '/user/login'
		},
	},
})
